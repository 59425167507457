import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Form, FormGroup, Label, Input } from "reactstrap";
import html2canvas from "html2canvas";
import api from "../../Axios";
import Pdf from "react-to-pdf";
import moment from "moment";
import Datatable from "react-bs-datatable";
import ColorPicker from "rc-color-picker";
import { noop } from "jquery";
import jsPDF from "jspdf";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import { Modal, Button } from "react-bootstrap";
import Swal from "sweetalert2/dist/sweetalert2.js";
const ref = React.createRef();
const ViewOrder = () => {
  const params = useParams();

  // all data
  const [data, setData] = useState([]);

  const [courierName, setCourierName] = useState("");
  const [courierId, setCourierId] = useState("");

  // const[productName,se]
  const [table, setTable] = useState();
  const [courierIdData, setCourierIdData] = useState("");
  const [gst, setGST] = useState("");
  const [courierNameData, Data] = useState("");
  // const [inputData, setInputData] = useState({courierId:"",courierName:""});
  const [bankDetails, setBankDetails] = useState();
  const [paymentStatus, setPaymentStatus] = useState();
  const [cartData, setCartData] = useState([]);
  const [purchaseDate, setPurchaseDate] = useState();
  const [purchaseTime, setPurchaseTime] = useState();
  const [DeliveryDate, setDeliveryDate] = useState();
  const [render, setRender] = useState(true);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // var list=null;

  function format(x) {
    return x.toString().split(",")[0].length > 3
      ? x
        .toString()
        .substring(0, x.toString().split(".")[0].length - 3)
        .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
      "," +
      x.toString().substring(x.toString().split(".")[0].length - 3)
      : x.toString();
  }

  const formatter = new Intl.NumberFormat("en-IN", {
    maximumSignificantDigits: 3,
  });

  const styles = StyleSheet.create({
    page: { backgroundColor: "white" },
    section: { textAlign: "center", margin: 100 },
  });

  function handleSubmit(e) {
    e.preventDefault();

    api
      .post("/admin/orders/trackingInfo", {
        trackingInfo: courierId + "," + " " + courierName,
        orderId: params.id,
      })

      .then((res) => {
        Swal.fire({
          icon: "success",
          timer: 4000,
          buttons: false,
          showConfirmButton: false,
          text: res.data.data,
        }).then((data) => {
          window.location.reload();
          // setTimeout(() => {
          //   setErr("");
          // }, 5000);
        });
        setCourierName("");
        setCourierId("");
        console.log(res.data.data);
        // notify(res.data.data, "success");
        // setprodDescription("");
        // setData({ productDescription: [] });
        // setData({tags:[]})
        // setSubmitRender(!submitRender);
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          timer: 4000,
          buttons: false,
          showConfirmButton: false,
          text: err.response.data.error.message,
        });
        console.log(err);
      });
  }

  const header = [
    { title: "ID", prop: "id", sortable: true, filterable: true },
    {
      title: "Product Name",
      prop: "productName",
      sortable: true,
      filterable: true,
    },
    {
      title: "Tax Percentage",
      prop: "tax",
      sortable: true,
      filterable: true,
    },
    {
      title: "Total Amount",
      prop: "totalPrice",
      sortable: true,
      filterable: true,
    },
    {
      title: "Quantity",
      prop: "units",
      sortable: true,
      filterable: true,
    },
    {
      title: "Variant Image",
      prop: "variantImage",
      sortable: true,
      filterable: true,
    },
    {
      title: "Variant Color",
      prop: "variantColor",
      sortable: true,
      filterable: true,
    },
    { title: "Timestamp", prop: "createdAt", sortable: true, filterable: true },
  ];

  const body = [
    {
      id: 1,
      name: "Helen Banks",
      company: "Brainlounge",
      email: "hbanks0@networkadvertising.org",
      phone: "386-(842)278-0044",
      date: moment().subtract(1, "days").format("Do MMMM YYYY"),
    },
    {
      id: 2,
      name: "Russell Wright",
      company: "Trilith",
      email: "rwright1@istockphoto.com",
      phone: "63-(139)594-8042",
      date: moment().subtract(2, "days").format("Do MMMM YYYY"),
    },
  ];

  const customLabels = {
    first: "<<",
    last: ">>",
    prev: "<",
    next: ">",
    show: "Display ",
    entries: " rows",
    noResults: "There is no data to be displayed",
  };

  useEffect(() => {
    api
      .post("/admin/orders/orderDetails", { orderId: params.id })
      .then((res) => {
        console.log("cart data", res.data.data.fetchCart.singleProductPrice);
        setData(res.data.data);
        setRender(!render);
        //
        setBankDetails(Number(res.data.data.fetchOrder.paidAmount));
        setCartData(res.data.data.fetchCart);
        setPaymentStatus(res.data.data.fetchOrder.paymentStatus);
        setCourierIdData(res.data.data.fetchOrder.trackingInfo);
        setPurchaseDate(
          moment(res.data.data.fetchOrder.createdAt).format(
            "MMMM D, YYYY, h:mm:ss a"
          )
        );
        setPurchaseTime();
        setDeliveryDate(
          moment(res.data.data.fetchOrder.updatedAt).format("MMMM D, YYYY")
        );
        setTable(
          res.data.data.fetchCart.map((info, i) => ({
            ...info,
            id: i + 1,
            productName: (
              <span style={{ textTransform: "capitalize" }}>
                {info.productName}
              </span>
            ),
            variantImage: (
              <img
                src={process.env.REACT_APP_BASEDIR + info.variantImage}
                width={83}
                height={83}
                alt="Variant Image"
              />
            ),
            createdAt: moment(info.createdAt).format("MMM D ddd Y"),
            variantColor: (
              <ColorPicker
                animation="slide"
                color={info.variantColor}
                className="color-css"
              />
            ),
          }))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  //   console.log("cartsss", cartData);
  //   let datas = courierIdData.split("")
  //   let data1 = datas[0];
  //   let data2 = datas[1];
  const bankData = [
    {
      // content: "\f47f",
      title: "Delivery Type",
      data: "deliveryType",
    },
    {
      // content: "\f47f",
      title: "Order Id ",
      data: "orderId",
    },
    {
      // content: "\f47f",
      title: "Order Status ",
      data: "orderStatus",
    },
    {
      // content: "\f47f",
      title: "Paid Amount ",
      data: "paidAmount",
    },
    {
      // content: "\f47f",
      title: "Payment Mode ",
      data: "paymentMethod",
    },
    {
      // content: "\f47f",
      title: "Payment Status ",
      data: "paymentStatus",
    },
    {
      // content: "\f47f",
      title: "Transaction Id ",
      data: "paymentTransactionId",
    },
    {
      // content: "\f47f",
      title: "Total Amount ",
      data: "totalAmount",
    },
    {
      // content: "\f47f",
      title: "Transaction Status ",
      data: "txnStatus",
    },
  ];

  function checkArray(data) {
    if (Array.isArray(data)) return data;
    else if (typeof data === "object") return [data];
    else return [];
  }
  console.log("data", checkArray(data));

  return (
    <div>
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title">Order Details</h1>
              </div>
            </div>

            {/* Mid card */}

            {/* new details container */}
            <div className="row">
              <div className="col-xl-12 col-md-12 col-sm-12">
                <section className="box ">
                  {checkArray(data).map((info) => {
                    // just mapping to get info varaiable
                    return (
                      <div className="content-body pt-3">
                        <div className="row list-item-bold">
                          {/* Customer Profile Details */}
                          <div className="col-xl-4 col-sm-6 col-8">
                            <h5>Customer Profile Details</h5>
                            <ul>
                              <li>
                                Name : <span>{data.fetchUser.userName}</span>
                              </li>
                              <li>
                                Email :{" "}
                                <span style={{ textTransform: "lowercase" }}>
                                  {data.fetchUser.email}
                                </span>
                              </li>
                              <li>
                                Phone : <span>{data.fetchUser.phone}</span>
                              </li>
                              <li>
                                Mail verification :{" "}
                                <span>{data.fetchUser.isMailVerified}</span>
                              </li>
                              <li>
                                Phone verification :{" "}
                                <span>{data.fetchUser.isPhoneVerified}</span>
                              </li>
                              <li>
                                Customer status :{" "}
                                <span>{data.fetchUser.status}</span>
                              </li>
                              <li>
                                Date of birth :{" "}
                                <span>{data.fetchUser.dob}</span>
                              </li>
                              <li>
                                Gender : <span>{data.fetchUser.gender}</span>
                              </li>
                            </ul>
                          </div>

                          {/* Address Details */}
                          <div className="col-xl-4 col-sm-6 col-8">
                            <h5>Address Details</h5>
                            <ul>
                              <li>
                                Street : <span>{data.fetchAddress.street}</span>
                              </li>
                              <li>
                                Landmark :{" "}
                                <span>{data.fetchAddress.landmark}</span>
                              </li>
                              <li>
                                PIN Code :{" "}
                                <span>{data.fetchAddress.zipcode}</span>
                              </li>
                              <li>
                                City : <span>{data.fetchAddress.city}</span>
                              </li>
                              <li>
                                District :{" "}
                                <span>{data.fetchAddress.district}</span>
                              </li>

                              <li>
                                State : <span>{data.fetchAddress.state}</span>
                              </li>
                              <li>
                                Address Type :{" "}
                                <span>{data.fetchAddress.addressType}</span>
                              </li>
                            </ul>
                          </div>

                          {/* Order Details */}
                          <div className="col-xl-4 col-sm-6 col-8">
                            <h5>Order Details</h5>
                            <ul>
                              <li>
                                Order ID: <span>{data.fetchOrder.orderId}</span>
                              </li>
                              <li>
                                Ordered on:{" "}
                                <span>
                                  {moment(data.fetchOrder.createdAt).format(
                                    "DD MMM YYYY hh:mm A"
                                  )}
                                </span>
                              </li>
                              <li>
                                Total Amount:{" "}
                                <span>{data.fetchOrder.totalAmount}</span>
                              </li>
                              <li>
                                Paid Amount:{" "}
                                <span>{data.fetchOrder.paidAmount}</span>
                              </li>
                              {/* <li>
                                Payment Method:{" "}
                                <span>{data.fetchOrder.paymentMethod}</span>
                              </li> */}
                              {/* <li>
                                Payment Intent:{" "}
                                <span>{data.fetchOrder.paymentIntent}</span>
                              </li> */}
                              {/* <li>
                                Payment Transaction ID:{" "}
                                <span>
                                  {data.fetchOrder.paymentTransactionId}
                                </span>
                              </li> */}
                              <li>
                                Transaction Status:{" "}
                                <span>{data.fetchOrder.txnStatus}</span>
                              </li>

                              <li>
                                Payment Status:{" "}
                                <span>{data.fetchOrder.paymentStatus}</span>
                              </li>
                              <li>
                                Order Status:{" "}
                                <span>{data.fetchOrder.orderStatus}</span>
                              </li>
                              {/* <li>
                                Reviewed Status:{" "}
                                <span>{data.fetchOrder.isReviewed}</span>
                              </li> */}
                              {/* <li>
                                Delivery Type:{" "}
                                <span>{data.fetchOrder.deliveryType}</span>
                              </li> */}
                              <li>
                                Courier Id:{" "}
                                <span>
                                  {data.fetchOrder.trackingInfo.split(",")[0]}
                                </span>
                              </li>
                              <li>
                                Courier Name:{" "}
                                <span>
                                  {data.fetchOrder.trackingInfo.split(",")[1]}
                                </span>
                              </li>
                            </ul>
                          </div>

                          {/* Add Tracking Information */}
                          <div className="col-10 col-sm-5">
                            <Form onSubmit={handleSubmit}>
                              <h5>Add Tracking Information</h5>
                              <FormGroup>
                                <Input
                                  type="text"
                                  name="courierId"
                                  id="exampleEmail7"
                                  placeholder="Enter Courier Id"
                                  onChange={(e) => setCourierId(e.target.value)}
                                />
                              </FormGroup>
                              <FormGroup>
                                <Input
                                  type="text"
                                  name="courierName"
                                  id="exampleEmail7"
                                  placeholder="Enter Courier Name"
                                  onChange={(e) =>
                                    setCourierName(e.target.value)
                                  }
                                />
                              </FormGroup>
                              <FormGroup style={{ marginBottom: "0px" }}>
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                >
                                  Add tracking Info
                                </button>
                              </FormGroup>
                            </Form>
                          </div>

                          <div className="col-10 mt-5">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={() => {
                                setShow(true);
                              }}
                            >
                              Generate Invoice
                            </button>
                          </div>
                        </div>

                        {/*
                  dummy */}

                        <div className="col-lg-4">
                          <div className="uprofile">
                            <div className="d-flex justify-content-center">
                              <Modal show={show} onHide={handleClose}>
                                <Modal.Header closeButton>
                                  <Modal.Title>Invoice</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                  <div className="row">
                                    <div class="container">
                                      <Document ref={ref}>
                                        <Page size="A4" style={styles.page}>
                                          <div>
                                            {/* Company details */}
                                            <div class="row text-center contact-info">
                                              <div class="col-lg-12 col-md-12 col-sm-12">
                                                {/* title */}
                                                <h1
                                                  style={{
                                                    textAlign: "center",
                                                    fontSize: "16px",
                                                    fontWeight: "600",
                                                  }}
                                                >
                                                  Invoice Details
                                                </h1>

                                                <hr />
                                                {/* Company details */}
                                                <span
                                                  style={{
                                                    fontSize: "14px",
                                                  }}
                                                >
                                                  <strong>
                                                    Company Name :{" "}
                                                  </strong>{" "}
                                                  <a
                                                    href="https://bhumidoris.com/"
                                                    target="_blank"
                                                  >
                                                    Bhumi Doris
                                                  </a>{" "}
                                                  <br /> &nbsp;
                                                  <strong>Email : </strong>{" "}
                                                  <a href="mailto:info@bhumidoris.com">
                                                    info@bhumidoris.com
                                                  </a>{" "}
                                                  <br /> &nbsp;
                                                  <strong>Call : </strong>{" "}
                                                  <a href="tel: +919842757058">
                                                    +91 9842757058"
                                                  </a>{" "}
                                                  <br />
                                                </span>

                                                <hr />
                                              </div>
                                            </div>

                                            {/* customer & paymentdetails */}
                                            <div class="row pad-top-botm client-info">
                                              {/* CUSTOMER INFORMATION */}
                                              <div
                                                class="col-sm-6"
                                                style={{
                                                  marginTop: "-20px",
                                                  padding: "25px",
                                                }}
                                              >
                                                <h4
                                                  style={{
                                                    textTransform: "upperCase",
                                                    fontSize: "16px",
                                                    lineHeight: "1.2",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  Customer Information
                                                </h4>
                                                <ul
                                                  className="list-item-bold"
                                                  style={{
                                                    listStyle: "none",
                                                    paddingLeft: 0,
                                                  }}
                                                >
                                                  <li>
                                                    Name :{" "}
                                                    <span>
                                                      {data.fetchUser.userName}
                                                    </span>
                                                  </li>

                                                  <li>
                                                    Street :{" "}
                                                    <span>
                                                      {data.fetchAddress.street}{" "}
                                                      {
                                                        data.fetchAddress
                                                          .landmark
                                                      }{" "}
                                                      {
                                                        data.fetchAddress
                                                          .zipcode
                                                      }{" "}
                                                      {data.fetchAddress.city}
                                                      {
                                                        data.fetchAddress
                                                          .district
                                                      }{" "}
                                                      {data.fetchAddress.state}
                                                    </span>
                                                  </li>
                                                </ul>
                                              </div>

                                              {/* PAYMENT DETAILS */}
                                              <div
                                                class="col-sm-6"
                                                style={{
                                                  marginTop: "-20px",
                                                  padding: "25px",
                                                }}
                                              >
                                                <h4
                                                  style={{
                                                    textTransform: "upperCase",
                                                    fontSize: "16px",
                                                    lineHeight: "1.2",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  Payment <br />
                                                  Details
                                                </h4>

                                                <ul
                                                  className="list-item-bold"
                                                  style={{
                                                    listStyle: "none",
                                                    paddingLeft: 0,
                                                  }}
                                                >
                                                  <li>
                                                    Bill Amount :{" "}
                                                    <span>
                                                      {
                                                        data.fetchOrder
                                                          .totalAmount
                                                      }
                                                    </span>
                                                  </li>

                                                  <li>
                                                    Payment Status:{" "}
                                                    <span>
                                                      {
                                                        data.fetchOrder
                                                          .paymentStatus
                                                      }
                                                    </span>
                                                  </li>

                                                  <li>
                                                    Purchased Date & Time:{" "}
                                                    <br />
                                                    <span>
                                                      {moment(
                                                        data.fetchOrder
                                                          .createdAt
                                                      ).format(
                                                        "DD MMM YYYY hh:mm A"
                                                      )}
                                                    </span>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>

                                            {/* product table details */}
                                            <div class="row">
                                              <div class="col-lg-12 col-md-12 col-sm-12">
                                                <div class="table-responsive">
                                                  <table
                                                    style={{
                                                      textAlign: "left",
                                                    }}
                                                    class="table table-striped table-bordered table-hover"
                                                  >
                                                    <thead>
                                                      <tr>
                                                        <th>Product</th>
                                                        <th>Quantity.</th>
                                                        {/* <th>Unit Price</th> */}
                                                        <th>GST</th>
                                                        <th>Sub Total</th>
                                                      </tr>
                                                    </thead>

                                                    <tbody>
                                                      {cartData.map((data) => (
                                                        <>
                                                          <tr>
                                                            <td
                                                              style={{
                                                                textTransform:
                                                                  "capitalize",
                                                              }}
                                                            >
                                                              {data.productName}
                                                            </td>
                                                            <td
                                                              style={{
                                                                textAlign:
                                                                  "center",
                                                              }}
                                                            >
                                                              {data.units}
                                                            </td>

                                                            {/* <td>
                                                              {" "}
                                                              ₹{" "}
                                                              {formatter.format(
                                                                data.singleProductPrice
                                                              )}
                                                            </td> */}
                                                            <td>
                                                              {" "}
                                                              ₹{" "}
                                                              {formatter.format(
                                                                data.inclusiveGST
                                                              )}
                                                            </td>
                                                            <td>
                                                              ₹{" "}
                                                              {formatter.format(
                                                                data.totalPrice
                                                              )}
                                                            </td>
                                                          </tr>
                                                        </>
                                                      ))}
                                                    </tbody>
                                                  </table>
                                                </div>
                                                <hr />

                                                <h5>
                                                  {" "}
                                                  Total Amount : ₹{" "}
                                                  {new Intl.NumberFormat(
                                                    "en-IN"
                                                  ).format(bankDetails)}
                                                </h5>
                                              </div>
                                            </div>
                                          </div>
                                        </Page>
                                      </Document>
                                    </div>
                                  </div>
                                </Modal.Body>
                                <div class="row pad-top-botm">
                                  <div class="col-lg-12 col-md-12 col-sm-12">
                                    <hr />

                                    <Pdf
                                      targetRef={ref}
                                      filename={data.fetchOrder.orderId}
                                      size="A4"
                                    >
                                      {({ toPdf }) => (
                                        <Button
                                          onClick={() => {
                                            toPdf();
                                          }}
                                        >
                                          Generate Pdf
                                        </Button>
                                      )}
                                    </Pdf>
                                  </div>
                                </div>
                              </Modal>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </section>
              </div>
            </div>
            {/* Mid card end */}
            <div className="row">
              <div className="col-xl-12 col-md-12 col-sm-12">
                <section className="box ">
                  <header className="panel_header">
                    <h2 className="title float-left">Product Lists</h2>
                  </header>
                  <div className="content-body">
                    <div className="row">
                      <div className="col-lg-12 dt-disp">
                        <Datatable
                          tableHeader={header}
                          tableBody={table}
                          keyName="userTable"
                          tableClass="striped table-hover table-responsive"
                          rowsPerPage={10}
                          rowsPerPageOption={[5, 10, 15, 20, 30]}
                          initialSort={{ prop: "id", isAscending: true }}
                          //   onSort={onSortFunction}
                          labels={customLabels}
                        />
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ViewOrder;

import React, { useEffect, useState, useRef } from "react";
import { Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import {} from "components";

import Cropper from "./Cropper";
import api from "../Axios";
import NotificationAlert from "react-notification-alert";
import Select from "react-select";
import { customStyles } from "../assets/css/CustomStyles";

const Welcome = () => {
  const notiRef = useRef();
  const [data, setData] = useState({
    image: "",
    message: "",
    // content: "",
  });

  const [render, setRender] = useState(false);

  function notify(msg, type) {
    var type = type;
    var options = {};
    options = {
      place: "tc",
      message: (
        <div className="notification-msg">
          <div className="text-center">{msg}</div>
        </div>
      ),
      type: type,
      icon: "",
      autoDismiss: 3,
    };

    notiRef.current.notificationAlert(options);
  }

  function handleChange(e) {
    setData({ ...data, [e.target.name]: e.target.value });
  }

  function handleImageChange(e) {
    setData({ ...data, image: e });
  }

  function handleSubmit(e) {
    e.preventDefault();

    const formdata = new FormData();
    Object.entries(data).map((data) => {
      formdata.append(data[0], data[1]);
    });

    api
      .post("/admin/shop/home", formdata)
      .then((res) => {
        setData({
          image: "",
          message: "",
        });

        notify(res.data.data, "success");
        setRender(!render);
      })
      .catch((err) => {
        console.log(err);
      });
    e.currentTarget.reset();
  }
  // console.log("CategoryList", data.bannerFor);

  return (
    <div className="content">
      <div className="notification-popup">
        <NotificationAlert ref={notiRef} />
      </div>
      <Row>
        <Col xs={12} md={12}>
          <div className="page-title">
            <div className="float-left">
              <h1 className="title"> Welcome Attributes</h1>
            </div>
          </div>

          <div className="col-12">
            <section className="box ">
              <header className="panel_header">
                <h2 className="title float-left">Add Welcome Attributes</h2>
              </header>
              <div className="content-body">
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-8">
                    <Form onSubmit={handleSubmit}>
                      <FormGroup>
                        <Label htmlFor="bannerImage">Pop Up Image</Label>

                        <Cropper
                          imageStore={handleImageChange}
                          aspectRatio={16 / 9}
                          reset={render}
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label htmlFor="exampleEmail7">Welcome Message</Label>
                        <Input
                          type="text"
                          name="message"
                          id="exampleEmail7"
                          onChange={handleChange}
                          placeholder="Enter Your Message"
                          required
                        />
                      </FormGroup>

                      <FormGroup style={{ marginBottom: "0px" }}>
                        <button type="submit" className="btn btn-primary">
                          Submit
                        </button>
                      </FormGroup>
                    </Form>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Welcome;

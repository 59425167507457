import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Row, Col } from "reactstrap";
import api from "../../Axios";
import moment from "moment";
import Datatable from "react-bs-datatable";
import ColorPicker from "rc-color-picker";
import { noop } from "jquery";

const ViewReturns = () => {
  const params = useParams();
  const [data, setData] = useState();
  const [table, setTable] = useState();
  const [render, setRender] = useState(true);
  // var list=null;
  console.log("Params", params);
  const header = [
    { title: "ID", prop: "id", sortable: true, filterable: true },
    {
      title: "Product Name",
      prop: "productName",
      sortable: true,
      filterable: true,
    },
    {
      title: "Tax Percentage",
      prop: "tax",
      sortable: true,
      filterable: true,
    },
    {
      title: "Total Amount",
      prop: "totalPrice",
      sortable: true,
      filterable: true,
    },
    {
      title: "Quantity",
      prop: "units",
      sortable: true,
      filterable: true,
    },
    {
      title: "Variant Image",
      prop: "variantImage",
      sortable: true,
      filterable: true,
    },
    {
      title: "Variant Color",
      prop: "variantColor",
      sortable: true,
      filterable: true,
    },
    { title: "Timestamp", prop: "createdAt", sortable: true, filterable: true },
  ];


  const bankData = [
    {
      // content: "\f47f",
      title: "Bank Details",
      data: "bankDetails",
    },
    {
      // content: "\f47f",
      title: "Order Id ",
      data: "orderId",
    },
    {
      // content: "\f47f",
      title: "Created At ",
      data: "createdAt",
    },
    {
      // content: "\f47f",
      title: "Order Status ",
      data: "orderStatus",
    },
    {
      // content: "\f47f",
      title: "Paid Amount ",
      data: "paidAmount",
    },
    {
      // content: "\f47f",
      title: "Reason For Return ",
      data: "reason",
    },
    {
      // content: "\f47f",
      title: "Return Id ",
      data: "returnId",
    },
   
    {
      // content: "\f47f",
      title: "Total Amount ",
      data: "totalAmount",
    },
    
    {
      // content: "\f47f",
      title: "Updated At ",
      data:"updatedAt",
    },
  ];
  const body = [
    {
      id: 1,
      name: "Helen Banks",
      company: "Brainlounge",
      email: "hbanks0@networkadvertising.org",
      phone: "386-(842)278-0044",
      date: moment().subtract(1, "days").format("Do MMMM YYYY"),
    },
    {
      id: 2,
      name: "Russell Wright",
      company: "Trilith",
      email: "rwright1@istockphoto.com",
      phone: "63-(139)594-8042",
      date: moment().subtract(2, "days").format("Do MMMM YYYY"),
    },
  ];
  const customLabels = {
    first: "<<",
    last: ">>",
    prev: "<",
    next: ">",
    show: "Display ",
    entries: " rows",
    noResults: "There is no data to be displayed",
  };

  useEffect(() => {
    api
      .post("/admin/returns/returnDetails", { returnId: params.id })
      .then((res) => {
        console.log(res.data.data);
        setData(res.data.data);
        setRender(!render);
        setTable(
          res.data.data.fetchCart.map((info,i) => ({
            ...info,
            id:(
              <span style={{ textTransform: "capitalize" }}>
                {++i}
              </span>
            ),
            variantImage: (
              <img
                src={process.env.REACT_APP_BASEDIR+info.variantImage}
                width={83}
                height={83}
                alt="Variant Image"
              />
            ),
            createdAt: moment(info.createdAt).format(
              "MMM D ddd Y"
            ),
            variantColor: (
              <ColorPicker
                animation="slide"
                color={info.variantColor}
                className="color-css"
              />
            ),
          }))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);


  function checkArray(data) {
    if (Array.isArray(data)) return data;
    else if (typeof data === "object") return [data];
    else return [];
  }
  console.log("data", checkArray(data));

  return (
    <div>
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title">Returns Details</h1>
              </div>
            </div>
            <div className="row">
              {checkArray(data).map((info) => (
                <div className="col-xl-12 col-md-12 col-sm-12">
                  <section className="box profile-page">
                    {/* <div className="content-body"> */}
                    <div className="col-12">
                      <div className="uprofile">
                        <div className="d-flex justify-content-center">
                          <div className="uprofile-image">
                            <img
                              alt=""
                              src={process.env.REACT_APP_BASEDIR+info.fetchUser.profilePic}
                              className="img-fluid"
                              width={100}
                              height={100}
                            />
                          </div>
                        </div>
                        <div className="uprofile-name col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                          <div className="d-flex flex-column justify-content-center text-center">
                            <h3 className="uprofile-owner">
                              {info.fetchUser.userName}
                            </h3>
                            {/* <div className="clearfix"></div> */}
                            <p className="uprofile-title">
                              Created At :{" "}
                              {moment(info.fetchUser.createdAt).format(
                                "MMM D ddd Y"
                              )}
                            </p>
                            {/* <div className="clearfix"></div> */}
                          </div>

        {/*------------------- Details ------------------------------------------------------- */}
                          <div className="row pt-2"> {/* ROW START*/ }
                            <div className="col-lg-6 col-md-12 col-sm-12">
                              <div className="d-flex flex-column text-center">
                                <h3 className="uprofile-owner">Profile</h3>
                              </div>
                              <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6">
                                  <ul>
                                    {/* <li
                                      style={{
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {info.fetchUser.alaisName}
                                    </li> */}
                                    <li> Phone No : {info.fetchUser.phone}</li>
                                    <li>{info.fetchUser.email}</li>
                                    <li
                                      style={{
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      Gender : {info.fetchUser.gender}
                                    </li>
                                    <li
                                      style={{
                                        textTransform: "capitalize",
                                      }}
                                    >
                                     DOB :  {info.fetchUser.dob}
                                    </li>
                                    <li
                                      style={{
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      Email verified :
                                      {info.fetchUser.isMailVerified}
                                    </li>
                                    <li
                                      style={{
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      Mobile verified :
                                      {info.fetchUser.isPhoneVerified}
                                    </li>
                                  </ul>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6">
                                  {info.fetchAddress !== null ? (
                                    <ul>
                                      <li
                                        style={{
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {" "}
                                        Address Type :
                                        {info.fetchAddress.addressType}
                                      </li>
                                      <li
                                        style={{
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {" "}
                                        Primary Address :
                                        {info.fetchAddress.primary}
                                      </li>
                                      <li
                                        style={{
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {info.fetchAddress.street}
                                      </li>
                                      <li
                                        style={{
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {info.fetchAddress.landmark}
                                      </li>
                                      <li
                                        style={{
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {info.fetchAddress.city +
                                          " ," +
                                          info.fetchAddress.zipcode}
                                      </li>
                                      <li
                                        style={{
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {info.fetchAddress.district +
                                          " , " +
                                          info.fetchAddress.state}
                                      </li>
                                    </ul>
                                  ) : (
                                    <li
                                      style={{
                                        textTransform: "capitalize",
                                        listStyle: "none",
                                      }}
                                    >
                                      No Address Found
                                    </li>
                                  )}
                                </div>
                              </div>
                            </div>

                            {/* Bank Details */}
                            <div className="col-lg-6 col-md-12 col-sm-12">
                              <div className="d-flex flex-column text-center">
                                <h3 className="uprofile-owner">Bank Details</h3>
                              </div>
                              <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6">
                                  <ul class="bank">
                                    {bankData.map((data, i) =>
                                      i < 4 ? (
                                        <>
                                          <li
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            {data.title} :{" "}
                                            {info.fetchReturn[data.data]}
                                          </li>
                                        </>
                                      ) : (
                                        ""
                                      )
                                    )}
                                  </ul>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6">
                                  <ul class="bank">
                                    {bankData.map((data, i) =>
                                      i > 4 ? (
                                        <>
                                          <li
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            {data.title} :{" "}
                                            {info.fetchReturn[data.data]}
                                           
                                          </li>
                                        </>
                                      ) : (
                                        ""
                                      )
                                    )}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>{/* ROW END*/ }
                        </div>
                      </div>
                    </div>
                    {/* </div> */}
                  </section>
                </div>
              ))}

              {/* Bank Details */}
            </div>

            <div className="row">
              <div className="col-xl-12 col-md-12 col-sm-12">
                <section className="box ">
                  <header className="panel_header">
                    <h2 className="title float-left">Product Lists</h2>
                  </header>
                  <div className="content-body">
                    <div className="row">
                      <div className="col-lg-12 dt-disp">
                        <Datatable
                          tableHeader={header}
                          tableBody={table}
                          keyName="userTable"
                          tableClass="striped table-hover table-responsive"
                          rowsPerPage={10}
                          rowsPerPageOption={[5, 10, 15, 20, 30]}
                          initialSort={{ prop: "id", isAscending: true }}
                          //   onSort={onSortFunction}
                          labels={customLabels}
                        />
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ViewReturns;

// {checkArray(data).length > 0
//   ? checkArray(data).map((info) => (
//       <div className="col-xl-6 col-md-6 col-sm-6">
//         <section className="box profile-page">
//           <div className="col-12">
//             <div className="uprofile">

//             </div>
//           </div>
//         </section>
//       </div>
//     ))
//   : ""}

import React, { useState, useEffect, useRef } from "react";
import { Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import TagsInput from "react-tagsinput";
import "rc-color-picker/assets/index.css";
import ColorPicker from "rc-color-picker";
import Cropper from "../Cropper";
import api from "../../Axios";
import NotificationAlert from "react-notification-alert";
import { useParams } from "react-router-dom";

const AddVariant = () => {
  const notiRef = useRef();
  const params = useParams();
  const variantId = params.id;

  const [data, setData] = useState({
    productId: "",
    productName: "",
    variantName: "",
    variantImage: [],
    altTags: [],
    variantColor: [],
    actualPrice: [],
    discountPrice: [],
    tax: "",
  });
  const [variantcount, setVariantCount] = useState(1);
  const [submitRender, setSubmitRender] = useState(false);
  const [render, setRender] = useState(false);
  const [loading, setLoading] = useState(true);

  const parse = (data) => {
    try {
      return Array.isArray(JSON.parse(data)) ? JSON.parse(data) : [];
    } catch {
      return [];
    }
  };

  function notify(msg, type) {
    var type = type;
    var options = {};
    options = {
      place: "tc",
      message: (
        <div className="notification-msg">
          <div className="text-center">{msg}</div>
        </div>
      ),
      type: type,
      icon: "",
      autoDismiss: type == "danger" ? 5 : 3,
    };
    notiRef.current.notificationAlert(options);
    var redirect =
      type === "success"
        ? setTimeout(() => (window.location.href = "/variantList"), 1400)
        : "";
  }

  //---------- VAIRANT DETAILS --------
  useEffect(() => {
    api
      .post("/admin/product/getVariant", { variantId })
      .then((res) => {
        console.log(res.data.data);
        setData({
          ...res.data.data,
          variantImage: parse(res.data.data.variantImage),
          altTags: parse(res.data.data.altTags),
          variantColor: parse(res.data.data.variantColor),
          actualPrice: parse(res.data.data.actualPrice),
          discountPrice: parse(res.data.data.discountPrice),
        });
        setVariantCount(parse(res.data.data.variantImage).length);
        setLoading(!loading);
      })
      .catch((error) => console.log("error", error));
  }, []);

  function pop(val) {
    console.log("values", val);
    if (data.variantImage.length > 1) {
      console.log("IFval", val);
      data.variantImage.pop();
      data.variantColor.pop();
      data.actualPrice.pop();
      data.discountPrice.pop();
      setVariantCount(val - 1);
    } else {
      console.log("ELSEval", val);
      setVariantCount(val - 1);
    }
  }

  function setTags(e) {
    console.log(e);
    setData({ ...data, altTags: e });
    setRender(!render);
  }

  function handleChange(e) {
    setData({ ...data, [e.target.name]: e.target.value });
    setRender(!render);
  }

  function handleArrayChange(e, i) {
    if (e.color) {
      console.log(e.color);
      data.variantColor[i] = e.color;
      setData({ ...data, variantColor: data.variantColor });
    } else if (e.target) {
      data[e.target.name][i] = e.target.value;
      setData({ ...data, [e.target.name]: data[e.target.name] });
    }
    setRender(!render);
  }

  function handleImageChange(i, e) {
    data["variantImage"][i] = e;
    console.log(e);
    setData({ ...data, variantImage: data["variantImage"] });
  }

  function handSubmit(e) {
    e.preventDefault();
    e.currentTarget.reset();
    const final = {
      variantImage: data.variantImage,
      variantId: variantId,
      productName: data.productName,
      variantName: data.variantName,
      tags: data.altTags,
      variantColor: data.variantColor,
      actualPrice: data.actualPrice,
      discountPrice: data.discountPrice,
    };
    console.log(final);
    api
      .post("/admin/product/updateVariant", final)
      .then((res) => {
        console.log(res.data.data);
        notify(res.data.data, "success");
        setSubmitRender(!submitRender);
        setData({
          ...data,
          productName: "",
          altTags: [],
          actualPrice: [],
          discountPrice: [],
          variantImage: [],
        });
        // setVariantCount(1);
      })
      .catch((err) => {
        console.log(err);
        notify(err, "danger");
      });
  }
  console.log("datasssss:", data);
  return (
    <div>
      <div className="notification-popup">
        <NotificationAlert ref={notiRef} />
      </div>
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title">Product Variant</h1>
              </div>
            </div>

            <div className="col-12">
              <section className="box ">
                <header className="panel_header">
                  <h2 className="title float-left">Update Variant</h2>
                </header>
                {loading ? (
                  " "
                ) : (
                  <>
                    <div className="content-body">
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-8">
                          <Form onSubmit={handSubmit}>
                            <FormGroup>
                              <Label htmlFor="exampleEmail7">
                                Variant Name
                              </Label>
                              <Input
                                type="text"
                                name="variantName"
                                value={data.variantName}
                                style={{ textTransform: "capitalize" }}
                                onChange={handleChange}
                                required
                              />
                            </FormGroup>

                            <FormGroup>
                              <Label htmlFor="exampleText">Tags</Label>
                              <div className="form-group">
                                <div className="controls">
                                  <TagsInput
                                    value={data.altTags}
                                    name="tags"
                                    onChange={setTags}
                                    onlyUnique={true}
                                  />
                                </div>
                              </div>
                            </FormGroup>

                            {Array.from({ length: variantcount }, (x, i) => (
                              <>
                                {i > 0 ? (
                                  <>
                                    <br />
                                    <div
                                      className="mt-3"
                                      style={{ height: "10px" }}
                                    ></div>
                                  </>
                                ) : (
                                  ""
                                )}
                                <FormGroup>
                                  <Label htmlFor="exampleFile">
                                    Variant Image
                                  </Label>
                                  <br />
                                  <span
                                    style={{ color: "red", fontWeight: "bold" }}
                                  >
                                    {" "}
                                    Dimensions : 480 x 480
                                  </span>{" "}
                                  <br />
                                  <span
                                    style={{ color: "red", fontWeight: "bold" }}
                                  >
                                    {" "}
                                    Size: Max 3mb
                                  </span>
                                  <br />
                                  <br />
                                  <img
                                    src={
                                      process.env.REACT_APP_BASEDIR +
                                      data.variantImage[i]
                                    }
                                    width={130}
                                    height={130}
                                    alt="img"
                                    style={{ marginBottom: "10px" }}
                                  />
                                  <Cropper
                                    imageStore={handleImageChange}
                                    index={i}
                                    reset={submitRender}
                                    aspectRatio={1 / 1}
                                  />
                                </FormGroup>

                                <FormGroup>
                                  <Label htmlFor="exampleText">Color</Label>
                                  <div
                                    className="form-group"
                                    style={{
                                      textAlign: "left",
                                    }}
                                  >
                                    <ColorPicker
                                      defaultColor={data.variantColor[i]}
                                      animation="slide"
                                      style={{ width: "" }}
                                      onChange={(e) => handleArrayChange(e, i)}
                                    />
                                  </div>
                                </FormGroup>

                                <FormGroup>
                                  <Label htmlFor="exampleText">
                                    Actual Price
                                  </Label>
                                  <Input
                                    type="text"
                                    name="actualPrice"
                                    value={data.actualPrice[i]}
                                    onChange={(e) => handleArrayChange(e, i)}
                                    required
                                  />
                                </FormGroup>

                                <FormGroup>
                                  <Label htmlFor="exampleText">
                                    Discount Price
                                  </Label>
                                  <Input
                                    type="text"
                                    name="discountPrice"
                                    value={data.discountPrice[i]}
                                    onChange={(e) => handleArrayChange(e, i)}
                                    required
                                  />
                                </FormGroup>
                                {/* </div> */}
                              </>
                            ))}
                            {/* <FormGroup>
                              <div
                                className="btn btn-primary"
                                onClick={() =>
                                  setVariantCount(variantcount + 1)
                                }
                              >
                                +
                              </div>
                              {variantcount > 1 ? (
                                <div
                                  className="btn btn-primary"
                                  onClick={() => pop(variantcount)}
                                >
                                  -
                                </div>
                              ) : (
                                ""
                              )}
                            </FormGroup> */}

                            <FormGroup style={{ marginBottom: "0px" }}>
                              <button type="submit" className="btn btn-primary">
                                Submit
                              </button>
                            </FormGroup>
                          </Form>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </section>
            </div>

            {/* <ProductList /> */}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AddVariant;
